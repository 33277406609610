<script>
import appConfig from '@src/app.config'

export default {
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
  data() {
    return {
      show: false,
    }
  },
}
</script>

<template>
  <b-overlay :show="show" rounded="sm">
    <div id="app">
      <!--
    Even when routes use the same component, treat them
    as distinct and create the component again.
      -->
      <RouterView :key="$route.fullPath" />
    </div>
  </b-overlay>
</template>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Design variables and utilities from src/design.
@import '@design';
</style>
