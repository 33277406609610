import store from '@state/store'
import axios from '@/axios'

// auth related routes
const authRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => lazyLoadView(import('@views/pages/account/login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ path: '/' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => lazyLoadView(import('@views/pages/account/register')),
  //   meta: {
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters['auth/loggedIn']) {
  //         // Redirect to the home page instead
  //         next({ name: 'dashboard' })
  //       } else {
  //         // Continue to the login page
  //         next()
  //       }
  //     },
  //   },
  // },
  {
    path: '/confirm-account',
    name: 'confirm-account',
    component: () => lazyLoadView(import('@views/pages/account/confirm')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ path: '/' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: () =>
      lazyLoadView(import('@views/pages/account/forgetPassword')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ path: '/' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { path: '/' } : { ...routeFrom })
      },
    },
  },
]

const publicRoutes = [
  {
    path: '/reset_password',
    name: 'reset-password',
    component: () => lazyLoadView(import('@views/pages/public/forgetPassword')),
  },
  {
    path: '/privacy_notice',
    name: 'privacy-notice',
    component: () => lazyLoadView(import('@views/pages/public/privacy_notice')),
  },
  {
    path: '/terms_of_use',
    name: 'terms-of-use',
    component: () => lazyLoadView(import('@views/pages/public/terms_of_use')),
  },
]

// error pages
const errorPagesRoutes = [
  {
    path: '/404',
    name: '404',
    component: require('@views/pages/secondary/error-404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  {
    path: '/500',
    name: '500',
    component: require('@views/pages/secondary/error-500').default,
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]

// dashboard
const dashboardRoutes = [
  {
    path: '/',
    name: 'Dashboard',
    header: '',
    icon: 'home',
    role: 'superAdmin',
    // badge: {
    //   text: '1',
    //   varient: 'success',
    // },
    component: () => lazyLoadView(import('@views/pages/dashboard/dashboardV2')),
    meta: { authRequired: true, superAdmin: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

// apps
// const calendarAppsRoutes = [
//   {
//     path: '/',
//     name: 'Dashboard',
//     header: '',
//     icon: 'home',
//     component: () => lazyLoadView(import('@views/pages/dashboard/dashboard')),
//     meta: { authRequired: true },
//     props: (route) => ({ user: store.state.auth.currentUser || {} }),
//   }
// ];

const contentRoutes = [
  // {
  //   path: '/contents',
  //   name: 'Content',
  //   header: 'Function',
  //   icon: 'file-text',
  //   meta: { authRequired: true },
  //   component: {
  //     render(c) {
  //       return c('router-view')
  //     },
  //   },
  //   props: (route) => ({ user: store.state.auth.currentUser || {} }),
  //   children: [
  //     {
  //       path: 'contentlist',
  //       name: 'Content',
  //       component: () => lazyLoadView(import('@views/pages/content')),
  //     },
  //     {
  //       path: 'Hashtagelist',
  //       name: 'Hashtage',
  //       component: () =>
  //         lazyLoadView(import('@views/pages/hashtage/index.vue')),
  //     },
  //   ],
  // },
  {
    path: '/contents/contentlist',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/content')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/contents/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/content/create')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/paidcontent/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/paidcontent/create')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/videocontent/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/videocontent/create')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },


]

const subscriptionRoutes = [
  {
    path: '/subscription',
    name: 'Subscription',
    header: 'Function',
    icon: 'file-text',
    meta: { authRequired: true },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
    children: [
      {
        path: 'freeContent',
        name: 'Free Content',
        component: () => lazyLoadView(import('@views/pages/content')),
      },
      {
        path: 'muayFemur',
        name: 'Muay Femur',
        component: () =>
          lazyLoadView(import('@views/pages/subscription/muayFemur')),
      },
      {
        path: 'lastestFight',
        name: 'Latest Fight',
        component: () =>
          lazyLoadView(import('@views/pages/subscription/lastestFight')),
      },
      //   {
      //     path: 'muayThaiNews',
      //     name: 'Muay Thai News',
      //     component: () =>
      //       lazyLoadView(import('@views/pages/subscription/muayThaiNews')),
      //   },
      {
        path: 'exclusiveTraining',
        name: 'Exclusive Training',
        component: () =>
          lazyLoadView(import('@views/pages/subscription/exclusiveTraining')),
      },
      {
        path: 'premiumMembers',
        name: 'Premium Members',
        component: () =>
          lazyLoadView(import('@views/pages/subscription/premiumMembers')),
      },
    ],
  },
  {
    path: '/subscription/muayThaiNews/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/subscription/muayThaiNews/create')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/subscription/muayFemur/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/subscription/muayFemur/create')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/subscription/lastestFight/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/subscription/lastestFight/create')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/subscription/premiumMembers/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/subscription/premiumMembers/create')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/subscription/exclusiveTraining/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(
        import('@views/pages/subscription/exclusiveTraining/create')
      ),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const bannerRoutes = [
  {
    path: '/banner',
    name: 'Banner',
    header: '',
    role: 'admin',
    icon: 'image',
    component: () => lazyLoadView(import('@views/pages/banner')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/banner/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/banner/create')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const paymentsRoutes = [
  {
    path: '/payments',
    name: 'Order',
    header: '',
    role: 'admin',
    icon: 'dollar-sign',
    component: () => lazyLoadView(import('@views/pages/payment')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/invoice/:id',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',

    component: () => lazyLoadView(import('@views/pages/payment/invoice')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const OrderRoutes = [
  {
    path: '/order',
    name: 'Order',
    header: 'Function',
    role: 'admin',
    icon: 'dollar-sign',
    meta: { authRequired: true },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
    children: [
      {
        path: 'course',
        name: 'Course',
        component: () =>
          lazyLoadView(import('@views/pages/order/categoryOrder')),
      },
      {
        path: 'subscription',
        name: 'Subscription',
        component: () =>
          lazyLoadView(import('@views/pages/order/subscriptionOrder')),
      },
    ],
  },
]
let muaythaiRoutes = [
  {
    path: '/category/child/:id',
    name: '',
    header: '',
    icon: '',
    role: 'admin',

    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/child/index.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/category/child/create/:id',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',

    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/child/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },

  {
    path: '/category/child/sub/:id',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',

    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/child/sub/index.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/category/child/sub/create/:id',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/child/sub/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/category/child/sub/form/:id',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/child/sub/form.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  // ========= Cover =========
  {
    path: '/category/cover/:id',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/category/cover.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },

  {
    path: '/category/editvideo',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/editvideo.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },

  // ========= Training Basic =========

  {
    path: '/category/trainingbasic/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/trainingbasic/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },

  // ========= Workout =========
  {
    path: '/category/workout/video/:id',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/workout/children/index.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/category/workout/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/workout/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/category/workout/video/create/:id',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/workout/children/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },

  // ========= Thai fighter =========
  {
    path: '/category/thaifighter/fighter',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(
        import('@views/pages/category/thaifighter/children/fighter/index.vue')
      ),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/category/thaifighter/fighter/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(
        import('@views/pages/category/thaifighter/children/fighter/create.vue')
      ),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/category/thaifighter/video',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(
        import('@views/pages/category/thaifighter/children/vdo/index.vue')
      ),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/category/thaifighter/video/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(
        import('@views/pages/category/thaifighter/children/vdo/create.vue')
      ),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/category/thaifighter/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/thaifighter/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  // ========= Courses =========
  {
    path: '/category/courses/video',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/courses/children/index.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/category/courses/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/courses/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/category/courses/video/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/courses/children/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },

  // ----------- Create sub-sub menu --------------------------------
  {
    path: '/category/child/:child/subinsub/:id',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/child/subinsub/index.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/category/child/:child/subinsub/:id/create',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/child/subinsub/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/category/child/:child/subinsub/:id/create/:subid',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/category/child/subinsub/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },

  {
    path: '/category/child/sub/subsubcategory/:id',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(
        import('@views/pages/category/child/subinsub/video/index.vue')
      ),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },

  {
    path: '/category/child/sub/subsubcategory/create/:id',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(
        import('@views/pages/category/child/subinsub/video/create.vue')
      ),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },

  // ---------- end sub-sub menu --------------------------------
]

const newPremiumRoutes = [
  {
    path: '/newPremium',
    name: 'New Premium',
    header: '',
    role: 'admin',
    icon: 'image',
    component: () => lazyLoadView(import('@views/pages/newPremium')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/newPremium/showData',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/newPremium/showData')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/newPremium/content',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/newPremium/content/index')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/newPremium/content/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/newPremium/content/create')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/newPremium/content/createVideo',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/newPremium/content/createVideo')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/newPremium/content/createVideocontent',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(
        import('@views/pages/newPremium/content/createVideocontent')
      ),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const trainingIdeaRoutes = [
  {
    path: '/trainingIdea',
    name: 'Training Idea',
    header: '',
    role: 'admin',
    icon: 'book-open',
    component: () => lazyLoadView(import('@views/pages/trainingIdea')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/trainingIdea/bodyParts',
    name: '',
    header: '',
    icon: '',
    component: () =>
      lazyLoadView(import('@views/pages/trainingIdea/bodyParts')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/trainingIdea/workouts',
    name: '',
    header: '',
    icon: '',
    component: () => lazyLoadView(import('@views/pages/trainingIdea/workouts')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/trainingIdea/manage',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/trainingIdea/manage/index')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/trainingIdea/manage/fighter',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/trainingIdea/manage/fighter')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/trainingIdea/manage/bodyParts',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/trainingIdea/manage/bodyParts')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/trainingIdea/manage/workouts',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/trainingIdea/manage/workouts')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const manage = [
  {
    path: '',
    name: 'Category',
    icon: 'box',
    role: 'admin',

    component: {
      render(c) {
        return c('router-view')
      },
    },
    meta: { authRequired: true },

    props: (route) => ({ user: store.state.auth.currentUser || {} }),
    children: [
      {
        path: 'category',
        name: 'Category',
        component: () => lazyLoadView(import('@views/pages/category')),
      },
      {
        path: 'categoryHashtagelist',
        name: 'Label',
        component: () =>
          lazyLoadView(import('@views/pages/category/hashtag.vue')),
      },
    ],
  },
  {
    path: '/manage/create/category',
    name: '',
    header: '',
    icon: 'user',
    role: 'admin',

    component: () => lazyLoadView(import('@views/pages/category/create.vue')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },

  {
    path: '/notification/create',
    name: 'Notification',
    header: '',
    icon: 'bell',
    role: 'admin',

    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/notification/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const usersRoutes = [
  {
    path: '/user',
    name: 'List Member',
    header: 'Customize',
    icon: 'user',
    role: 'admin',

    component: () => lazyLoadView(import('@views/pages/user')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const masterRoutes = [
  {
    path: '/fighter',
    name: 'List Fighter',
    header: '',
    icon: 'user',
    role: 'admin',

    component: () => lazyLoadView(import('@views/pages/fighter')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/fighter/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/fighter/create')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/fighter/video/:id',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/fighter/video')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/fighter/video/create/:id',
    name: '',
    header: '',
    icon: '',
    // role: 'superAdmin',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/fighter/video/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const tageRoute = [
  {
    path: '/tage',
    name: 'Hashtag',
    header: '',
    icon: 'hash',
    role: 'admin',

    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/hashtage/index.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const contactRoutes = [
  {
    path: '/contact',
    name: 'Chat',
    header: '',
    // badge: {
    //   text: '1',
    //   varient: 'success',
    // },
    icon: 'mail',
    role: 'admin',

    component: () => lazyLoadView(import('@views/pages/contact')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const discountRoutes = [
  {
    path: '/discount',
    name: 'Discount',
    header: '',
    icon: 'percent',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/discount')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/discount/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/discount/create')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const businessRoutes = [
  {
    path: '/business',
    name: 'Business',
    header: '',
    role: 'admin',
    icon: 'image',
    component: () => lazyLoadView(import('@views/pages/business/index.vue')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/business/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/business/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/business/edit/:id',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/business/edit.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const gymRoutes = [
  {
    path: '/gym',
    name: 'Gym',
    header: '',
    role: 'admin',
    icon: 'image',
    component: () => lazyLoadView(import('@views/pages/gym/index.vue')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/gym/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/gym/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/gym/edit/:id',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/gym/edit.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const placeRoutes = [
  {
    path: '/place',
    name: 'Nearby local',
    header: '',
    role: 'admin',
    icon: 'image',
    component: () => lazyLoadView(import('@views/pages/place/index.vue')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/place/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/place/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/place/edit/:id',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/place/edit.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const attractionRoutes = [
  {
    path: '/attraction',
    name: 'Attraction',
    header: '',
    role: 'admin',
    icon: 'image',
    component: () => lazyLoadView(import('@views/pages/attraction/index.vue')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/attraction/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/attraction/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/attraction/edit/:id',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/attraction/edit.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const nearapproveRoutes = [
  {
    path: '/nearapprove',
    name: 'Near By Local Approve',
    header: '',
    role: 'admin',
    icon: 'image',
    component: () => lazyLoadView(import('@views/pages/nearapprove/index.vue')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/nearapprove/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () =>
      lazyLoadView(import('@views/pages/nearapprove/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/nearapprove/edit/:id',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/nearapprove/edit.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const homeBannerRoutes = [
  {
    path: '/homebanner',
    name: 'Home Banner',
    header: '',
    role: 'admin',
    icon: 'image',
    component: () => lazyLoadView(import('@views/pages/homeBanner/index.vue')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/homebanner/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/homeBanner/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/homebanner/edit/:id',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/homeBanner/edit.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const stadiumRoutes = [
  {
    path: '/stadium',
    name: 'Stadium',
    header: '',
    role: 'admin',
    icon: 'image',
    component: () => lazyLoadView(import('@views/pages/stadium/index.vue')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/stadium/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/stadium/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/stadium/edit/:id',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/stadium/edit.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]

const eventRoutes = [
  {
    path: '/event',
    name: 'Event',
    header: '',
    role: 'admin',
    icon: 'image',
    component: () => lazyLoadView(import('@views/pages/event/index.vue')),
    meta: { authRequired: true },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/event/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/event/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/event/edit/:id',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/event/edit.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]
const contentandcategoryRoutes = [
  {
    path: '/contentandcategory',
    name: 'ContentAndCategory',
    header: '',
    role: 'admin',
    icon: 'image',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/contentandcategory')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/contentandcategory/create',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/contentandcategory/create.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/contentandcategory/edit/:id',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/contentandcategory/edit.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/contentandcategory/test',
    name: '',
    header: '',
    icon: '',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('@views/pages/contentandcategory/test.vue')),
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
]
// const projectAppsRoutes = [
//   {
//     path: '/apps/project',
//     name: 'Project',
//     icon: 'briefcase',
//     meta: { authRequired: true },
//     // create a container component
//     component: {
//       render(c) {
//         return c('router-view')
//       },
//     },
//     props: (route) => ({ user: store.state.auth.currentUser || {} }),
//     children: [
//       {
//         path: 'list',
//         name: 'List',
//         meta: { authRequired: true },
//         component: () =>
//           lazyLoadView(import('@views/pages/apps/project/list')),
//       },
//       {
//         path: 'detail',
//         name: 'Detail',
//         meta: { authRequired: true },
//         component: () =>
//           lazyLoadView(import('@views/pages/apps/project/detail')),
//       },
//     ],
//   }
// ];

// const taskAppsRoutes = [
//   {
//     path: '/apps/task',
//     name: 'Task',
//     icon: 'bookmark',
//     meta: { authRequired: true },
//     // create a container component
//     component: {
//       render(c) {
//         return c('router-view')
//       },
//     },
//     props: (route) => ({ user: store.state.auth.currentUser || {} }),
//     children: [
//       {
//         path: 'list',
//         name: 'Task List',
//         meta: { authRequired: true },
//         component: () =>
//           lazyLoadView(import('@views/pages/apps/tasks/task-list')),
//       },
//       {
//         path: 'task-board',
//         name: 'Kanban Board',
//         meta: { authRequired: true },
//         component: () =>
//           lazyLoadView(import('@views/pages/apps/tasks/task-board')),
//       },
//     ],
//   }
// ];

const appsRoutes = [
  // ...calendarAppsRoutes,
  ...bannerRoutes,
  ...contentRoutes,
  ...discountRoutes,
  ...subscriptionRoutes,
  ...newPremiumRoutes,
  ...trainingIdeaRoutes,
  ...muaythaiRoutes,
  ...manage,
  // ...courseRoutes,
  ...usersRoutes,

  ...masterRoutes,
  // ...paymentsRoutes,
  // ...tageRoute,
  ...contactRoutes,
  ...gymRoutes,
  ...businessRoutes,
/*   ...placeRoutes,
  ...attractionRoutes,
  ...nearapproveRoutes, */
  ...homeBannerRoutes,
  ...stadiumRoutes,
  ...eventRoutes,
  ...contentandcategoryRoutes
  // ...emailAppsRoutes,
  // ...projectAppsRoutes,
  // ...taskAppsRoutes
]

// pages
// const pagesRoutes = [
//   {
//     path: '/pages',
//     name: 'Pages',
//     icon: 'file-text',
//     header: 'Custom',
//     meta: { authRequired: true },
//     // create a container component
//     component: {
//       render(c) {
//         return c('router-view')
//       },
//     },
//     children: [
//       {
//         path: 'starter',
//         name: 'Starter',
//         component: () => lazyLoadView(import('@views/pages/secondary/starter')),
//       },
//
//       // {
//       //   path: 'invoice',
//       //   name: 'Invoice',
//       //   component: () => lazyLoadView(import('@views/pages/secondary/invoice')),
//       // },
//       // {
//       //   path: 'profile',
//       //   name: 'Profile',
//       //   component: () => lazyLoadView(import('@views/pages/secondary/profile/')),
//       // },
//       // {
//       //   path: 'activity',
//       //   name: 'Activity',
//       //   component: () =>
//       //     lazyLoadView(import('@views/pages/secondary/activity')),
//       // },
//       // {
//       //   path: 'pricing',
//       //   name: 'Pricing',
//       //   component: () => lazyLoadView(import('@views/pages/secondary/pricing')),
//       // },
//     ],
//   },
// ]

// // ui
// const uiRoutes = [
//   {
//     path: '/ui',
//     name: 'UI Elements',
//     icon: 'package',
//     header: 'Components',
//     meta: { authRequired: true },
//     // create a container component
//     component: {
//       render(c) {
//         return c('router-view')
//       },
//     },
//     children: [
//       {
//         path: 'bootstrap',
//         name: 'Bootstrap UI',
//         component: () => lazyLoadView(import('@views/pages/ui/bootstrap/')),
//       },
//       {
//         path: 'icons',
//         name: 'Icons',
//         // create a container component
//         component: {
//           render(c) {
//             return c('router-view')
//           },
//         },
//         children: [
//           {
//             path: 'feather',
//             name: 'Feather',
//             component: () =>
//               lazyLoadView(import('@views/pages/ui/icons/feather')),
//           },
//           {
//             path: 'unicons',
//             name: 'Unicons',
//             component: () =>
//               lazyLoadView(import('@views/pages/ui/icons/unicons')),
//           },
//         ],
//       },
//       {
//         path: 'widgets',
//         name: 'Widgets',
//         component: () => lazyLoadView(import('@views/pages/ui/widget/')),
//       },
//     ],
//   },
// ]

// // forms
// const formsRoutes = [
//   {
//     path: '/forms',
//     name: 'Forms',
//     icon: 'file-text',
//     meta: { authRequired: true },
//     // create a container component
//     component: {
//       render(c) {
//         return c('router-view')
//       },
//     },
//     children: [
//       {
//         path: 'basic',
//         name: 'Basic',
//         component: () => lazyLoadView(import('@views/pages/ui/forms/basic')),
//       },
//       {
//         path: 'advanced',
//         name: 'Advanced',
//         component: () => lazyLoadView(import('@views/pages/ui/forms/advanced')),
//       },
//       {
//         path: 'validation',
//         name: 'Validation',
//         component: () =>
//           lazyLoadView(import('@views/pages/ui/forms/validation')),
//       },
//       {
//         path: 'wizard',
//         name: 'Wizard',
//         component: () => lazyLoadView(import('@views/pages/ui/forms/wizard/')),
//       },
//       {
//         path: 'editor',
//         name: 'Editor',
//         component: () => lazyLoadView(import('@views/pages/ui/forms/editor')),
//       },
//       {
//         path: 'uploads',
//         name: 'File Uploads',
//         component: () => lazyLoadView(import('@views/pages/ui/forms/uploads')),
//       },
//     ],
//   },
// ]

// // tables
// const tablesRoutes = [
//   {
//     path: '/tables',
//     name: 'Tables',
//     icon: 'grid',
//     meta: { authRequired: true },
//     // create a container component
//     component: {
//       render(c) {
//         return c('router-view')
//       },
//     },
//     children: [
//       {
//         path: 'basic',
//         name: 'Basic Tables',
//         component: () =>
//           lazyLoadView(import('@views/pages/ui/tables/basic-table')),
//       },
//       {
//         path: 'advanced',
//         name: 'Advanced Tables',
//         component: () =>
//           lazyLoadView(import('@views/pages/ui/tables/advanced-table')),
//       },
//     ],
//   },
// ]

// // charts
// const chartsRoutes = [
//   {
//     path: '/charts',
//     name: 'Charts',
//     icon: 'pie-chart',
//     component: () => lazyLoadView(import('@views/pages/ui/chart/')),
//     meta: { authRequired: true },
//     props: (route) => ({ user: store.state.auth.currentUser || {} }),
//   },
// ]

const authProtectedRoutes = [
  ...dashboardRoutes,
  // ...paymentsRoutes,
  ...OrderRoutes,

  ...appsRoutes,
  // ...pagesRoutes,
  // ...uiRoutes,
  // ...formsRoutes,
  // ...testRoute
  // ...chartsRoutes,
  // ...tablesRoutes
]
const allRoutes = [
  ...authRoutes,
  ...publicRoutes,
  ...authProtectedRoutes,
  // ...errorPagesRoutes
]

export { allRoutes, authProtectedRoutes }

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@components/_loading').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    // error: require('@views/_timeout').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}

async function renderCategory() {
  let result = await axios.get('/api/category')
  result = result.data.map((x) => {
    return {
      path: `${x.category_name}`,
      name: `${x.category_name}`,
      meta: { authRequired: true },
      component: () =>
        lazyLoadView(import('@views/pages/category/trainingbasic/index.vue')),
    }
  })

  return result
}
