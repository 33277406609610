var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"metismenu",attrs:{"id":"side-menu"}},_vm._l((_vm.menuItems),function(item,index){return _c('li',{key:`item-${index}`,staticClass:"side-nav-title side-nav-item"},[(
        item.header && item.role === _vm.$store.state.auth.currentUser.data.role
      )?_c('p',{staticClass:"menu-title mb-0"},[_vm._v(_vm._s(item.header))]):_vm._e(),(_vm.hasItems(item))?_c('a',{staticClass:"side-nav-link",attrs:{"href":"javascript:void(0);"}},[(item.icon)?_c('feather',{attrs:{"type":item.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"menu-arrow"})],1):_vm._e(),(
        !_vm.hasItems(item) &&
          item.name &&
          (item.role === _vm.$store.state.auth.currentUser.data.role ||
            _vm.$store.state.auth.currentUser.data.role === 'superAdmin')
      )?_c('router-link',{staticClass:"side-nav-link side-nav-link-ref",attrs:{"tag":"a","to":`${item.path}`}},[(item.icon)?_c('feather',{attrs:{"type":item.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.name))]),(item.name === 'Chat' && _vm.activeNumber)?_c('span',{class:'badge badge-' + 'danger' + ' float-right font-size-11'},[_vm._v(_vm._s(_vm.activeNumber))]):_vm._e(),(item.badge)?_c('span',{class:'badge badge-' + item.badge.varient + ' float-right font-size-11'},[_vm._v(_vm._s(item.badge.text))]):_vm._e()],1):_vm._e(),(_vm.hasItems(item))?_c('ul',{staticClass:"nav-second-level"},_vm._l((item.children),function(subitem,key){return _c('li',{key:`sub-item-${key}`,class:{ 'side-nav-item': _vm.hasItems(subitem) }},[(_vm.hasItems(subitem))?_c('a',{staticClass:"side-nav-link-a-ref",attrs:{"href":"javascript:void(0);"}},[_vm._v("\n          "+_vm._s(subitem.name)+"\n          "),_c('span',{staticClass:"menu-arrow"})]):_vm._e(),(subitem.name)?_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"tag":"a","to":`${item.path}/${subitem.path}`}},[_vm._v(_vm._s(subitem.name))]):_vm._e(),(_vm.hasItems(subitem))?_c('ul',{staticClass:"nav-third-level",attrs:{"aria-expanded":"false"}},_vm._l((subitem.children),function(subSubitem){return _c('li',{key:`sub-sub-item-${subSubitem.name}`},[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"tag":"a","to":`${item.path}/${subitem.path}/${subSubitem.path}`}},[_vm._v(_vm._s(subSubitem.name))])],1)}),0):_vm._e()],1)}),0):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }